import { TestRunType } from "./settings";
import { computed, observable } from "mobx";
import { isNotBlank } from "core/utils";
import { type } from "core/serialization";
import { ProjectStore, SettingsStore } from "stores";

export enum TestFramework {
  CucumberJS = "CucumberJS",
  Cypress = "Cypress",
  NightWatch = "NightWatch",
  NUnit = "NUnit",
  Playwright = "Playwright",
  PyTest = "PyTest",
  TestNG = "TestNG",
  WebDriverIO = "WebDriverIO",
  Other = "Other",
}

export type TestingFrameworkVersion = CypressFrameworkVersion // Later, when we support more framework type versions, this value can become something like CypressFrameworkVersion | AnotherFrameworkVersion

export enum CypressFrameworkVersion {
  NineAndBelow = "<=9",
  TenAndAbove = ">=10"
}

export const compiledFrameworks = [TestFramework.NUnit, TestFramework.TestNG];

export function allowParallelizationOption(framework: TestFramework) {
  return (
    framework == TestFramework.NUnit ||
    framework == TestFramework.NightWatch ||
    framework == TestFramework.Cypress ||
    framework == TestFramework.Playwright ||
    framework == TestFramework.PyTest ||
    framework == TestFramework.WebDriverIO ||
    framework == TestFramework.TestNG
  );
}

export default class Project {
  id: number;
  accountId: number;
  name: string;
  description: string;
  key: string;
  gitProvider: string;
  gitOwner: string;
  gitRepo: string;
  defaultBranch: string;
  runner: TestRunType;
  @observable archived: boolean;
  hasTests: boolean;
  hasDeploy: boolean;
  testingFramework: TestFramework;
  testingFrameworkVersion?: TestingFrameworkVersion;
  workingDirectory: string;
  usingSelenium: boolean;
  usingWebdriverio: boolean;
  usingDotnetCore: boolean;
  retryFailedTests: boolean;
  usingCodeCoverage: boolean;
  defaultToParallelizeByFile: boolean;
  codeCoverageGreenPercentage: number;
  codeCoverageYellowPercentage: number;
  testTimeoutSeconds: number;
  testRunTimeoutMinutes: number;
  defaultMaxRunners: number;
  testTags: string[];
  testArtifacts: string[];
  testPackages: string[];
  recordVideo: boolean;
  cancelOlderTestRunsIfEquivalent: boolean;
  quarantineFlakyTests: boolean;

  constructor(data?: Partial<Project>) {
    Object.assign(this, data);
  }

  get hasBuildArtifacts() {
    return (
      this.runner == TestRunType.dotnet ||
      this.runner == TestRunType.dotnetcore ||
      this.runner == TestRunType.testng ||
      !this.hasRepo
    );
  }

  get hasRepo() {
    return isNotBlank(this.gitOwner) && isNotBlank(this.gitProvider) && isNotBlank(this.gitRepo);
  }

  fullTestName(projectTest: ProjectTest) {
    if (this.testingFramework == TestFramework.Cypress) {
      return projectTest.file;
    }

    if (this.testingFramework != TestFramework.NUnit) {
      return `${projectTest.file}/${projectTest.name}`;
    }

    return projectTest.name;
  }

  get allowParallelizationOption() {
    return allowParallelizationOption(this.testingFramework);
  }

  get isWindows() {
    return this.testingFramework == TestFramework.NUnit && !this.usingDotnetCore;
  }

  @computed
  get stats() {
    return ProjectStore.projectStats.find((s) => s.projectId == this.id);
  }
}
export class ProjectTest {
  id: number;
  projectId: number;
  name: string;
  file: string;
  fileFilter: string;
  testFilter: string;
  archived: boolean;
  quarantinedAt: string;

  get testFileName() {
    return this.file.endsWith(".dll") ? this.fileFilter : this.file;
  }

  @computed
  get nameLower() {
    return this.name.toLocaleLowerCase();
  }

  @computed
  get fileLower() {
    return this.file.toLocaleLowerCase();
  }

  @computed
  get quarantined() {
    return this.quarantinedAt != null;
  }

  matches(str: string) {
    return this.nameLower.includes(str) || this.fileLower.includes(str);
  }
}

export class Build {
  id: number;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  name: string;
  branch: string;
}

export class Deploy {
  id: number;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  environmentId: number;
  commit: string;
  branch: string;
  @type(Build)
  build: Build;

  constructor(data?: Partial<Deploy>) {
    if (data) {
      Object.assign(this, data);
    }
  }

  @computed
  get buildName() {
    return this.build ? this.build.name : "";
  }

  @computed
  get commitShort() {
    return this.commit?.substring(0, 10);
  }

  @computed
  get environment() {
    return SettingsStore.findEnvironment(this.environmentId);
  }
}

export class Commit {
  projectId: number;
  id: string;
  message: string;
  user: string;
  date: string;
}

export interface ProjectCommit {
  projectId: number;
  commit: string;
}

export class ProjectStats {
  projectId: number;
  lastMonthUniqueTestsRun: number;
  thisMonthUniqueTestsRun: number;
  deploysInLastThirtyDays: number;
  @type(Deploy)
  lastDeployment: Deploy;

  @computed
  get uniqueDiff() {
    return this.thisMonthUniqueTestsRun - this.lastMonthUniqueTestsRun;
  }

  @computed
  get uniqueDiffText() {
    return this.uniqueDiff > 0 ? `+${this.uniqueDiff}` : this.uniqueDiff.toString();
  }

  @computed
  get uniqueDiffType() {
    if (this.uniqueDiff < 0) return "negative";
    if (this.uniqueDiff > 0) return "positive";
    return "zero";
  }
}

export enum TestSuiteType {
  Accessibility = "Accessibility",
  Acceptance = "Acceptance",
  BlackBox = "BlackBox",
  EndToEnd = "EndToEnd",
  Functional = "Functional",
  Integration = "Integration",
  Load = "Load",
  Performance = "Performance",
  Regression = "Regression",
  Security = "Security",
  Smoke = "Smoke",
  Stress = "Stress",
  System = "System",
  Unit = "Unit",
  WhiteBox = "WhiteBox",
  Other = "Other",
}

export class TestSuite {
  id: number;
  archived: boolean;
  type: TestSuiteType;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  name: string;
  description: string;
  maxRunners: number;
  includeTags: string[];
  excludedTags: string[];
  testFilters: string[];
  testTimeoutSeconds: number;
  timeoutMinutes: number;
  parallelizeByFile: boolean;
  retryFailedTests: boolean;

  @computed
  get project() {
    return ProjectStore.find(this.projectId);
  }
}
