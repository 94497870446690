import { ItemStoreList } from "./item-store";
import Project, { Build, Commit, Deploy, ProjectCommit, ProjectStats, ProjectTest } from "models/project";
import API, { Response } from "core/api";
import { action, computed, observable, runInAction } from "mobx";
import { chain } from "lodash";
import ProjectForm from "models/forms/project-form";
import { cachedLookup } from "core/utils";
import ProjectRepoForm from "models/forms/project-repo-form";
import { Branch } from "models/settings";

export class ProjectStoreClass extends ItemStoreList<Project> {
  @observable loaded = false;
  @observable latestDeploysLoaded = false;
  @observable latestDeploys: Deploy[] = [];
  @observable projectStats: ProjectStats[] = [];
  @observable branches: Array<Branch> = [];

  async load(id: number) {
    const { data } = await API.get(`/projects/${id}`, Project);
    return this.add(data);
  }

  @action
  clear() {
    super.clear();
    this.loaded = false;
  }

  @action
  async ensureLoaded() {
    return this.loaded ? this.items : this.loadAll();
  }

  @action
  async loadAll() {
    const { data } = await API.getList("/projects", Project);
    return runInAction(() => {
      this.items = data;
      this.loaded = true;
      return data;
    });
  }

  findByKey(key: string) {
    return this.items.find((p) => p.key == key);
  }

  @computed
  get active() {
    return chain(this.items)
      .filter((p) => !p.archived)
      .sortBy("key")
      .sortBy((p) => p.name.toLocaleLowerCase())
      .value();
  }

  @computed
  get hasProjectWithDeploy() {
    return true;
  }

  @action
  async delete(id: number) {
    await API.delete(`/projects/${id}`);
    runInAction(() => (this.find(id).archived = true));
  }

  @action
  async save(form: ProjectForm | ProjectRepoForm, forceSubmit: boolean = false) {
    return (form as ProjectForm).submit(async (data) => {
      let result: Response<Project>;
      if (data.id) {
        result = await API.put(`/projects/${data.id}`, data, Project);
      } else {
        result = await API.post("/projects", data, Project);
      }

      return this.update(result.data);
    }, forceSubmit);
  }

  @action
  async attachTestCode(projectId: number) {
    const result = await API.get(`projects/${projectId}/attach-test-code`, Build);
    return result.data;
  }

  async loadProjectTests(projectId: number) {
    const url = `/projects/${projectId}/tests`;
    return cachedLookup(
      url,
      async () => {
        const result = await API.getList(url, ProjectTest);
        return result.data;
      },
      5,
    );
  }

  @action
  async loadProjectStats(projectId: number): Promise<ProjectStats> {
    await this.ensureLoaded();
    const url = `/projects/${projectId}/stats`;
    const result = await cachedLookup(url, () => API.get(url, ProjectStats), 5);

    return result.data;
  }

  @action
  async ensureLatestDeploysLoaded() {
    return this.latestDeploysLoaded ? this.latestDeploys : this.loadLatestDeploys();
  }

  @action
  async loadLatestDeploys() {
    const { data } = await API.getList("/deploys/latest", Deploy);
    return runInAction(() => {
      this.latestDeploys = data;
      this.latestDeploysLoaded = true;
      return data;
    });
  }

  hasDeploy(projectId: number, environmentId: number) {
    return !!this.latestDeploys.find((d) => d.projectId === projectId && d.environmentId === environmentId);
  }

  async loadDeploys(projectId: number) {
    const url = `/projects/${projectId}/deploys`;
    const result = await API.getList(url, Deploy);
    return result.data;
  }

  async loadQuarantinedTests(projectId: number) {
    const url = `/projects/${projectId}/quarantined-tests`;
    const result = await API.getList(url, ProjectTest);
    return result.data;
  }

  async dequarantineTest(projectId: number, projectTestId: number) {
    const url = `/projects/${projectId}/tests/${projectTestId}/dequarantine`;
    await API.put(url, {});
  }

  async quarantineTest(projectId: number, projectTestId: number) {
    const url = `/projects/${projectId}/tests/${projectTestId}/quarantine`;
    await API.put(url, {});
  }

  async loadBranches(provider: string, owner: string, repo: string) {
    const result = await API.getList(`/branches?provider=${provider}&owner=${owner}&repo=${repo}`, Branch);
    this.branches = result.data;
    return this.branches;
  }

  async loadBuilds(projectId: number) {
    const result = await API.getList(`/projects/${projectId}/builds`);
    return (result.data as Build[]);
  }

  async loadBuildNames(projectId: number) {
    const result = await API.getList(`/projects/${projectId}/builds`);
    return (result.data as Build[]).map((b) => {
      return b.name;
    });
  }

  async loadBuild(projectId: number, buildId: number) {
    const url = `/projects/${projectId}/builds/${buildId}`;
    const result = await cachedLookup(url, () => API.get(url, Build), 60);
    return result.data;
  }

  async loadCommits(projectId: number, branch?: string) {
    const url = `/projects/${projectId}/commits` + (branch ? `?branch=${branch}` : "");
    const result = await API.getList(url);
    return result.data as string[];
  }

  async loadCommit(projectId: number, commit: string) {
    const url = `/projects/${projectId}/commits/${commit}`;

    return cachedLookup(
      url,
      async () => {
        const result = await API.get(url, Commit);
        result.data.projectId = projectId;
        return result.data;
      },
      60,
      1,
    );
  }

  loadCommitList(projectCommits: ProjectCommit[]) {
    return Promise.all(
      projectCommits
        .filter((p) => p.commit && p.projectId)
        .map(({ projectId, commit }) => this.loadCommit(projectId, commit)),
    );
  }
}
